var winHeight = 0;
var resizeTimer; 
var bannerHeightAdjust = 0;
var  bannerHeightSmall = 0;

 resizeSlideview();

   
  function resizeSlideview() {



    if(jQuery('.page-template-alumni').length == 1){
      bannerHeightAdjust = 500
      bannerHeightSmall = 300;
    }else{
      bannerHeightAdjust = 100
      bannerHeightSmall = 250;
    }

    

    if(jQuery(window).height() > 600 && jQuery(window).width() > 600){
      winHeight = jQuery(window).height() - jQuery('.site-header').height() - bannerHeightAdjust; 
    }else {
      winHeight = bannerHeightSmall;
    }

   

    jQuery('.slide_viewer').height(winHeight + 'px');

  };

  jQuery( window ).resize(function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(resizeSlideview, 250); 
  });
  
  jQuery.fn.EduCoSlider = function() {
  
  return this.each(function() {

        
            var $this = jQuery(this);
            var $group = $this.find('.slide_group');
            var $slides = $this.find('.slide');
            
            $this.find('.slide_viewer').height(winHeight + 'px');
            var bulletArray = [];
            var currentIndex = 0;
            var timeout = 10000;
            
            function move(newIndex) {
              var animateLeft, slideLeft;
              
              advance();
              
              if ($group.is(':animated') || currentIndex === newIndex) {
                return;
              }
              
              bulletArray[currentIndex].removeClass('active');
              bulletArray[newIndex].addClass('active');
              
              if (newIndex > currentIndex) {
                slideLeft = '100%';
                animateLeft = '-100%';
              } else {
                slideLeft = '-100%';
                animateLeft = '100%';
              }
              
              $slides.eq(newIndex).css({
                display: 'block',
                left: slideLeft
              });
              $group.animate({
                left: animateLeft
              }, function() {
                $slides.eq(currentIndex).css({
                  display: 'none'
                });
                $slides.eq(newIndex).css({
                  left: 0
                });
                $group.css({
                  left: 0
                });
                currentIndex = newIndex;
              });
            }
            
            function advance() {
              clearTimeout(timeout);
              timeout = setTimeout(function() {
                if (currentIndex < ($slides.length - 1)) {
                  move(currentIndex + 1);
                } else {
                  move(0);
                }
              }, 12000);
            }
            var $nxtbtn = $this.find('.educo-directional-nav .next_btn');
            var $prevbtn = $this.find('.educo-directional-nav .previous_btn');
            var $slidebtns = $this.find('.educo-slide-buttons');

            $nxtbtn.on('click', function() {
              if (currentIndex < ($slides.length - 1)) {
                move(currentIndex + 1);
              } else {
                move(0);
              }
            });
            
            $prevbtn.on('click', function() {
              if (currentIndex !== 0) {
                move(currentIndex - 1);
              } else {
                move(3);
              }
            });
            
            jQuery.each($slides, function(index) {
              var $button = jQuery('<a class="slide_btn">&bull;</a>');
              
              if (index === currentIndex) {
                $button.addClass('active');
              }
              $button.on('click', function() {
                move(index);
              }).appendTo($slidebtns);
              bulletArray.push($button);
            });
            advance();
  });
};


(function( $ ) {


    jQuery('#mainBanner').EduCoSlider();
    jQuery('#testimonials').EduCoSlider();

    var carrousel = jQuery( ".carrousel" );

    jQuery( ".portrait" ).click( function(e){
      var src = jQuery(this).find(".pic").attr( "data-src-wide" );
      carrousel.find("img").attr( "src", src );
      carrousel.fadeIn( 200 );
    });


    carrousel.click( function(e){
      console.log('tar');
      carrousel.find( "img" ).attr( "src", '' );
      carrousel.fadeOut( 200 );
    } );

    carrousel.find( ".close" ).click( function(e){
      carrousel.find( "img" ).attr( "src", '' );
      carrousel.fadeOut( 200 );
    } );

}( jQuery ));

